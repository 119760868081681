<template>
    <div class="container-fluid rating">
        <div class="container rating__box" v-if="usersrating.length != 0">
            <div class="header_table">
                <div class="row">
                    <div class="col-6 title_table">Usuários</div>
                    <div class="col-3 title_table">Nota média</div>
                    <div class="col-3 title_table">Avaliações</div>
                </div>
            </div>
            <template v-for="(user,key) in usersrating">
                <div :key="key" class="rating__infobox">
                    <div class="rating__infobox--info">
                        <div class="col-6">{{user['user']}}</div>
                        <div class="col-3">{{user['media']}}</div>
                        <div class="col-3">{{user['avaliacoes']}}</div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script type="text/javascript">
import { RatingMixin } from "./Rating.mixin.js";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  created () {
  },
  data() {
    return {};
  },
  watch: {},
  props: {},
  name: "Rating",
  mixins: [RatingMixin],
};
</script>
<style src="./Rating.less" lang="less" />
