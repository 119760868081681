import { getCompleterate } from '../../services/logs.services'

export const RatingMixin = {
    data() {
        return {
            usersrating: []
        };
    },
    created() {},
    mounted() {
        this.getRating()
    },
    filters: {},
    watch: {},
    computed: {


    },
    methods: {
        async getRating() {
            try {
                const { data } = await getCompleterate()
                let users = data.data.users

                for (const i in users) {
                    this.usersrating.push({
                        'user': i,
                        'media': users[i]['media'].toFixed(2),
                        'avaliacoes': users[i]['avaliacoes']
                    })
                }


            } catch (e) {
                this.$tokentest(e.response.status)
            }
        }


    },
};